/* eslint-disable */
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Spinner,
  Grid,
  GridItem,
  Image,
 Text,
  Tag,
  Button,
  VStack,
  Input,
  Spacer,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody,ModalCloseButton, ModalFooter,useDisclosure, HStack
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNFTBalances, useWeb3Transfer } from 'react-moralis';
import Rarities from '../components/RR_nft_ranks.json'

interface NftProps {
  name: string;
  metadata: {
    image?: string;
    attributes: { trait_type: string; value: string | number }[];
  };
  address: string;
  id: string;
  type: string;
}

const Nft = ({ name, id, metadata, type, address }: NftProps) => {
  const [chosenId, setChosenId] = useState(id);
  const [receiver, setReceiver] = useState('');
  
  const [refreshKey, setRefreshKey]=useState('0');
  const { fetch, error, isFetching } = useWeb3Transfer({
    type: 'erc721',
    // eslint-disable-next-line
    receiver: receiver,
    contractAddress: address,
    tokenId: chosenId,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleReceiverChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setReceiver(event.target.value);
  };

 /* useEffect(() => {
    if (!isFetching && !error) {
      // eslint-disable-next-line
     //console.log(JSON.stringify(metadata.attributes));
    }
  }, [isFetching, error]);*/

  const imgClickedHandler = (event: React.MouseEvent<HTMLImageElement>) => {
    event.stopPropagation();
    setChosenId(id);
    setIsModalOpen(true);
    //console.log("right!");
    const hero = JSON.stringify(metadata?.attributes?.[0]);
    const hero1 = JSON.parse(hero);
    const background = JSON.stringify(metadata?.attributes?.[1]);
    const background1 = JSON.parse(background);
    const backgear = JSON.stringify(metadata?.attributes?.[2]);
    const backgear1 = JSON.parse(backgear);
    const armour = JSON.stringify(metadata?.attributes?.[3]);
    const armour1 = JSON.parse(armour);
    const headgear = JSON.stringify(metadata?.attributes?.[4]);
    const headgear1 = JSON.parse(headgear);
    const rarity = ClassRarity[metadata?.attributes?.[0]?.value] *
      ClassRarity[metadata?.attributes?.[1]?.value] *
      ClassRarity[metadata?.attributes?.[2]?.value] *
      ClassRarity[metadata?.attributes?.[3]?.value] *
      ClassRarity[metadata?.attributes?.[4]?.value] *
      ClassRarity[metadata?.attributes?.[5]?.value] *
      ClassRarity[metadata?.attributes?.[6]?.value];
    const stats = `
      Collection name: ${name}
      NFT Id: ${id}
      NFT type: ${type}
      Background: ${background1.value} (Rarity: ${ClassRarity[background1.value]})
      Backgear: ${backgear1.value} (Rarity: ${ClassRarity[backgear1.value]})
      Armour: ${armour1.value} (Rarity: ${ClassRarity[armour1.value]})
      Headgear: ${headgear1.value} (Rarity: ${ClassRarity[headgear1.value]})
      Hero: ${hero1.value} (Rarity: ${ClassRarity[hero1.value]})
      Total rarity: ${rarity}
      <img src="${metadata?.image?.replace('ipfs://', 'https://recklessrobots.mypinata.cloud/ipfs/')}">
    `;
  }

   
    


  const transferNft = () => {
    fetch();
  };

  const ClassRarity: Record<string, number> = {
    "GoldenSwords"	:	0.175	,
    "Green"	:	0.175	,
    "NoScarf"	:	0.38	,
    "Amethyst"	:	0.125	,
    "Blue"	:	0.175	,
    "BlueScarf"	:	0.205	,
    "Fiery"	:	0.225	,
    "Fire"	:	0.13	,
    "Flames"	:	0.185	,
    "GlowingGold"	:	0.11	,
    "GlowingYellow"	:	0.285	,
    "Gold"	:	0.11	,
    "GoldenWings"	:	0.09	,
    "GreenScarf"	:	0.16	,
    "Grey"	:	0.16	,
    "Knight"	:	0.18	,
    "Laser"	:	0.105	,
    "LightBlue"	:	0.15	,
    "Mark2"	:	0.155	,
    "MetalWings"	:	0.14	,
    "Ninja"	:	0.11	,
    "NoGear"	:	0.23	,
    "Ocean"	:	0.13	,
    "Original"	:	0.385	,
    "Red"	:	0.165	,
    "RedScarf"	:	0.255	,
    "Violet"	:	0.155	,
    "Yellow"	:	0.16	,
    

  
  
    
    // Add more values as needed
  };
  // get the rarity value for a specific trait value
  const getRarityValue = (traitType: string, traitValue: string | number) => {
    if (!metadata?.attributes || metadata.attributes.length === 0) {
      return 1; // default value when no attributes are present
    }
  
    const attribute = metadata.attributes.find((attr) => attr.trait_type === traitType);
    if (!attribute || attribute.value === undefined) {
      return 1; // default value when the attribute is not present or has no value
    }
  
  
  }

  // create a copy of the metadata object and add rarity values to the attributes
  const metadataWithRarity = { ...metadata };
  if (metadata?.attributes) {
    metadataWithRarity.attributes = metadata.attributes.map((attribute) => ({
      ...attribute,
      rarity: getRarityValue(attribute.trait_type, attribute.value),
    }));
  }

  const traitValues: { [key: string]: number } = {};
if (metadata?.attributes) {
  for (const attribute of metadata.attributes) {
    traitValues[attribute.trait_type] = attribute.value as number || 1;

  }
} else {
  metadata.attributes =  [];



  }
   
  


const rarityValues = metadata?.attributes
?.map((attr) => ClassRarity[attr.value])
.filter((rarity) => !isNaN(rarity)) || [0,45];

const totalRarity = rarityValues.reduce((a, b) => a * b, 1);



  const { isOpen, onOpen, onClose } = useDisclosure()


  return (
    <>


    <Modal  isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
    <ModalOverlay  />
    <ModalContent borderWidth={'50px'} bgColor={'black'}  className='nft-cards'>
    <ModalHeader textColor={'white'}>NFT Details</ModalHeader>
    <ModalCloseButton />
    <ModalBody>
          {metadata?.image && (
            <Image width={'50vw'} height='auto' borderRadius={'10px'}
              onClick={imgClickedHandler}
              src={metadata?.image.replace('ipfs://', 'https://ipfs.io/ipfs/')}
            />)}
           {name && id && (
          <Box textColor={'white'} p={1}>
               <VStack textAlign={'center'}>
          <Box textColor={'white'}>
            <Tag  padding={2} colorScheme={'white'} fontSize={'1.5vmin'}>
              {name} #{id} 
            </Tag>
     
     <HStack justifyContent={'center'}>
            <Tag  padding={2}colorScheme={'white'} fontSize={'1.5vmin'}>
            RANK: 
            </Tag> 
            {Rarities && Rarities[0]?.rank && (
  <Tag padding={2} colorScheme={'white'} fontSize={'1.5vmin'}>
    {String(JSON.stringify(Rarities[(Number(id)) - 1]?.rank))}
  </Tag>
)} </HStack>
          
           
          
          
            
          </Box>   
              <Text fontSize={'1.5vmin'}>
              {metadata?.attributes?.[0]?.trait_type ?? ''} -  {metadata?.attributes?.[0]?.value ?? ''} 
    </Text>
    
    <Text fontSize={'1.5vmin'}>
    {metadata?.attributes?.[1]?.trait_type ?? ''} -       {metadata?.attributes?.[1]?.value ?? ''} 
    
    </Text>
    <Text fontSize={'1.5vmin'}>
    {metadata?.attributes?.[2]?.trait_type ?? ''} -        {metadata?.attributes?.[2]?.value ?? ''} 
    
    </Text>
    <Text alignContent={'center'} fontSize={'1.5vmin'}>
    {metadata?.attributes?.[3]?.trait_type ?? ''} -  {metadata?.attributes?.[3]?.value ?? ''} 
    
    </Text>
    <Text fontSize={'1.5vmin'}>
    {metadata?.attributes?.[4]?.trait_type ?? ''} - {metadata?.attributes?.[4]?.value ?? ''} 
    
    </Text>
    <Text fontSize={'1.5vmin'}>
    {metadata?.attributes?.[5]?.trait_type ?? ''} - {metadata?.attributes?.[5]?.value ?? ''}
    
    </Text>
    <Text fontSize={'1.5vmin'}>
      {metadata?.attributes?.[6]?.trait_type ?? ''} - {metadata?.attributes?.[6]?.value ?? ''}
    </Text>
    <HStack justifyContent={'space-evenly'}>
      <Button  size='md'colorScheme="orangered" onClick={transferNft} fontSize="1.5vmin">
              SEND
            </Button>
            <Input fontSize={'1.5vmin'} placeholder="Receiver address" value={receiver} onChange={handleReceiverChange}></Input></HStack></VStack>
      </Box>)}
    </ModalBody>
    </ModalContent>
    </Modal>
       <Box  onClick={imgClickedHandler} backgroundColor="black" borderRadius="8px" overflow="hidden"> 
          {metadata?.image && (
           <Image
              
              src={metadata?.image.replace('ipfs://', 'https://ipfs.io/ipfs/')}
              width={'50vmax'}
              height={'auto'}
              borderRadius={'10px'}
            />
          )}
            <VStack textAlign={'center'}>
          <Box textColor={'white'}>
            <Tag  padding={2} colorScheme={'white'} fontSize={'1.5vmin'}>
              {name} #{id} 
            </Tag>
         
            <Tag  padding={2}colorScheme={'white'} fontSize={'1.5vmin'}>
          
            </Tag> 
            {Rarities && Rarities[0]?.rank && (
  <Tag padding={2} colorScheme={'white'} fontSize={'1.5vmin'}>
    RANK: {String(JSON.stringify(Rarities[(Number(id)) - 1]?.rank))}
  </Tag>
)}
            <Button  size='fit-content'colorScheme="orangered"  fontSize="1.5vmin">
              INFO & TRANSFER
            </Button>
          

            
          
            
          </Box>   </VStack>
        </Box>
        </>
  );}
  
          
      

interface NftGridProps {
  token_addresses?: string[] | undefined;
  address: string;
  chain: string;
  total?: boolean;
  refreshKey?:string;
  setRefreshKey?:string;
  value:string;
}


export const NftGrid = ({ address, refreshKey, setRefreshKey, value }: NftGridProps) => {

 

 
 

 
  const { data, error, isLoading } = useNFTBalances(
    {
      //@ts-ignore
      tokenAddresses: [value],
      chain: '0x19',
      address,
    },
    {
      autoFetch: true,
    }
  );

  if (isLoading) {
    return (
      <Box>
        <Spinner thickness="4px" color="blue.300" size="xl" />
      </Box>
    );
  }

  if (error) {
    return (
      <Box>
        <Alert status="error">
          <AlertIcon />
          <AlertTitle>{error.name}</AlertTitle>
          <AlertDescription>{error.message}</AlertDescription>
        </Alert>
       
      </Box>
    );
  }

  if (!data?.result || data.result.length === 0) {
    return (
      <Box>
        <Alert status="info">
          <AlertIcon />
          <AlertTitle>No results</AlertTitle>
          <AlertDescription>
            There are no NFTs associated with this address.
          </AlertDescription>
        </Alert>
      </Box>
    );
  }

  
  return (
    <VStack alignItems="start">
      


      
      <Grid templateColumns="repeat(auto-fit, minmax(200px, 1fr))" gap={6}>
        {data.result.map((nft:any) => (
          <GridItem  className='nft-cards' key={`${nft.token_address}${nft.token_id}`}>
            <Nft
              name={nft.name}
              id={nft.token_id}
              address={nft.token_address}
              metadata={nft.metadata}
              type={nft.contract_type}
  
            />
        
          </GridItem>
        ))}
      </Grid>
    </VStack>
  );

};

export default NftGrid;