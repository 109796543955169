import React from 'react';
import { Container, Spacer, Text, Flex, Img, Link } from '@chakra-ui/react';

import { Connect } from '../components/Connect';

import { Navigation } from './Navigation';
import RR from './RRLOGO.png';
import '../App.css';
import Twitter from '../assets/twitter.webp'
import EB from '../assets/eb.png'
import TG from '../assets/tg.png'
import CDC from '../assets/CDC.png'
import Medium from '../assets/medium.png'
import Discord from '../assets/discord.png'










const Header = () => {


 
  return (
    <Container maxW="full"  centerContent >
      <Img   width='20%' height= 'auto' src={RR}  alt=''></Img>
          <Flex wrap="nowrap" alignItems={'center'} mr={1} direction= 'column' gap='1' justify-content= 'center'>
                    <Flex wrap="nowrap" alignItems={'center'} mr={1} direction= 'row' gap='7' justify-content= 'center'>

          <Text paddingTop={'2vmax'} fontSize='3vw' fontWeight="100" color="#68738D">  {'RECKLESS COMMAND CENTER'}</Text>
        
</Flex>
<Flex 
  wrap="nowrap" 
  alignItems="center" 
  mr={1} 
  direction="row" 
  gap="1" 
  justifyContent="center"
  transform="scale(1.7)" 
  paddingBottom={'2vmax'}
  paddingTop={'2vmax'}
>
  <Link 
    style={{ textAlign: 'center', color: 'orangered', margin: '0.5vmax' }} 
    href="https://www.twitter.com/recklessrobots"
  >
    <img 
      style={{ borderRadius: '50%', width: '2vmax' }} 
      src={Twitter} 
      alt="Twitter"
    />
  </Link>
  <Link 
    style={{ textAlign: 'center', color: 'orangered', margin: '0.5vmax' }} 
    href="https://medium.com/@recklessrobots"
  >
    <img 
      style={{ borderRadius: '50%', width: '4vmax' }} 
      src={Medium} 
      alt="Medium"
    />
  </Link>
  <Link 
    style={{ textAlign: 'center', color: 'orangered', margin: '0.5vmax' }} 
    href="https://discord.gg/Nk4PHjdhcs"
  >
    <img 
      style={{ borderRadius: '50%', width: '2vmax' }} 
      src={Discord} 
      alt="Discord"
    />
  </Link>
  <Link 
    style={{ textAlign: 'center', color: 'orangered', margin: '0.5vmax' }} 
    href="https://t.me/recklessrobotsnft"
  >
    <img 
      style={{ borderRadius: '50%', width: '2vmax' }} 
      src={TG} 
      alt="Telegram"
    />
  </Link>
  <Link 
    style={{ textAlign: 'center', color: 'orangered', margin: '0.5vmax' }} 
    href="https://app.ebisusbay.com/drops/reckless-robots-legends"
  >
    <img 
      style={{ borderRadius: '50%', width: '2vmax' }} 
      src={EB} 
      alt="Ebisus Bay"
    />
  </Link>
  <Link 
    style={{ textAlign: 'center', color: 'orangered', margin: '0.5vmax' }} 
    href="https://crypto.com/nft/collection/32d67c24929e1b0441a37798f04a553c?tab=items"
  >
    <img 
      style={{ borderRadius: '50%', width: '2vmax' }} 
      src={CDC} 
      alt="Crypto.com"
    />
  </Link>
  <Connect />
</Flex>

       
        
            </Flex>
 
        
    
        
      <Flex wrap="nowrap" alignItems='center'  direction= 'row' justify-content= 'space-around'>  
        <Navigation  />
        </Flex>
        <Spacer />
       
    
    </Container>
  );
};

export default Header;
