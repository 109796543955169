import React, { useState, useEffect } from 'react';
import Decimal from 'decimal.js';
import { useMoralis, useNFTBalances } from 'react-moralis';

import { EvmChain } from "@moralisweb3/common-evm-utils";
import { Link, VStack, color } from '@chakra-ui/react';
import {
  Box,
  Button,
  Heading,
  Input,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Collapse,
  Spinner
} from '@chakra-ui/react';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalOverlay

 
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import Moralis from 'moralis-v1';
import './wallet.css';
import { link } from 'fs';

const RecklessWallets = Moralis.Object.extend({
  className: 'RecklessWallets'
});



const App = () => {
  const [wallets, setWallets] = useState(['']); // State to store the input address
  const { account } = useMoralis();
  const [address, setAddress] = useState('');
  const [assets, setAssets] = useState([]);

 
  const [buttonClicked, setButtonClicked] = useState(false);
  const [sortBy, setSortBy] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');
  const [showDetails, setShowDetails] = useState(false);
  const [selectedTokenAddress, setSelectedTokenAddress] = useState(null);

  const [realizedPnL, setRealizedPnL] = useState(Decimal(0));
  const [unrealizedPnL, setUnrealizedPnL] = useState(Decimal(0));
  const [totalPnL, setTotalPnL] = useState(Decimal(0));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [totalBuys, setTotalBuys] = useState(Decimal(0));
const [totalSells, setTotalSells] = useState(Decimal(0));
const [loading, setLoading] = useState(false);
const [showWallets, setShowWallets] = useState(false);

  useEffect(() => {
    const WalletsfromStorage1 = localStorage.getItem('walletsStorage');
    const safeJSONParse = (str) => {
      try {
        return JSON.parse(str);
      } catch (e) {
        console.error("Failed to parse JSON", e);
        return null;
      }
    }

    if (WalletsfromStorage1) {
     // console.log(WalletsfromStorage1);

      
      const parsedWallets = safeJSONParse(WalletsfromStorage1);
      setTimeout(() => {
        if (parsedWallets) {
          setWallets(parsedWallets);
        }
      }, 1000);
    
    }
  }, []);
  
  useEffect(() => {
    if (wallets && wallets.length > 0 && wallets[0]!=='' ){
      localStorage.setItem('walletsStorage', JSON.stringify(wallets)); // Store the array as a JSON string.
     // console.log(wallets);
    }
  }, [wallets]);
 
  
  useEffect(() => {
    if (account && !address && !wallets.includes(account) || (buttonClicked && wallets.length === 0)) {
      setWallets([account || '']);
    } else if (wallets.length > 0 && wallets[0] === "" && !account && address && !wallets.includes(address) && buttonClicked) {
      setWallets([address]);
    } else if (wallets.length > 0 && wallets[0].includes("0x") && !account && address && buttonClicked) {
      setWallets([...wallets, address]); // Use spread operator to add the address to the array.
    } else if (account && address && !wallets.includes(account)) {
      setWallets([...wallets, account]);
    } else if (account && address && !wallets.includes(address)) {
      setWallets([...wallets, address]); // Use spread operator to add the address to the array.
    }
    
   // console.log(wallets);
  }, [account, buttonClicked]);

  useEffect(() => {
    if (process.env.REACT_APP_MORALIS_API_KEY && wallets.length > 0 && (address || account || wallets !=='' ) ) {
      fetchAssets();
    }
  }, [account, buttonClicked, wallets]);




  useEffect(() => {
    if (sortBy) {
      const sortedAssets = [...assets].sort((a, b) => {
        if (a[sortBy] < b[sortBy]) {
          return sortDirection === 'asc' ? -1 : 1;
        }
        if (a[sortBy] > b[sortBy]) {
          return sortDirection === 'asc' ? 1 : -1;
        }
        return 0;
      });
      setAssets(sortedAssets);
    }
  }, [sortBy, sortDirection]);

  const fetchAssets = async () => {
    if (!wallets || wallets.length === 0 || wallets.every(wallet => !wallet)) {
      console.warn("No valid wallets available for fetching assets.");
      return;
    }
  
    try {
      const promises = wallets
        .filter(wallet => wallet) // Ensure wallet is not null, undefined, or empty
        .map(wallet =>
          fetch(`https://deep-index.moralis.io/api/v2.2/wallets/${wallet}/tokens?chain=cronos&exclude_spam=false&exclude_unverified_contracts=false`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'X-API-Key': process.env.REACT_APP_MORALIS_API_KEY,
            },
          }).then(response => response.json())
        );
  
      const results = await Promise.all(promises);
  
      const mergedData = results.reduce((merged, result) => {
        if (result.result) {
          result.result.forEach(item => {
            const existingItemIndex = merged.findIndex(mergedItem => mergedItem.name === item.name);
            if (existingItemIndex !== -1) {
              merged[existingItemIndex].balance_formatted += item.balance_formatted;
              merged[existingItemIndex].balance += item.balance;
              merged[existingItemIndex].usd_value += item.usd_value;
            } else {
              merged.push(item);
            }
          });
        }
        return merged;
      }, []);
  
      console.log("Fetched and merged asset data:", mergedData);
      setAssets(mergedData);
    } catch (error) {
      console.error("Error fetching assets:", error);
    }
  };
  

  const handleInputChange = (e) => {
    if (buttonClicked){
      setAddress(e.target.value);
      setWallets([...wallets, address])
    }
    else {    setAddress(e.target.value);}
  };

  const handleButtonClick = () => {
    if (address && !wallets.includes(address)) {
      // Update the wallets state with the new address
      setWallets(prevWallets => [...prevWallets, address]);
  
      // Clear the input after submitting the address
      setAddress('');
    }
  
    // Trigger button click state to control UI feedback
    setButtonClicked(true);
    setTimeout(() => {
      setButtonClicked(false);
    }, 1000);
  };

  const handleRemoveWallet = (walletToRemove) => {
    const updatedWallets = wallets.filter(wallet => wallet !== walletToRemove);
    setWallets(updatedWallets);
  
    if (updatedWallets.length === 0) {
      // If no wallets are left, clear the assets
      setAssets([]);
    } else {
      // Otherwise, fetch updated assets based on the new list of wallets
      setTimeout(() => {
       
        fetchAssets();
      }, 0);
    }
  };
  const calculateTotalValueUSD = () => {
    return assets.reduce((total, asset) => total + asset.usd_value, 0).toFixed(2);
  };

  const handleSort = (columnName) => {
    if (sortBy === columnName) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(columnName);
      setSortDirection('asc');
    }
  };
  const sortingOptions = [
    { label: 'Name', value: 'name' },
    { label: 'Value (USD)', value: 'usd_value' },
    { label: '24h Change', value: 'usd_price_24hr_percent_change' },
  ];
  
  const getActiveSortLabel = () => {
    const activeOption = sortingOptions.find((option) => option.value === sortBy);
    return activeOption ? activeOption.label : 'Sort By';
  };

  const calculateDailyChangeInUSD = (asset) => {
    if (asset) {
      return (asset.usd_value - ((asset.usd_value) / (1 + (asset.usd_price_24hr_percent_change / 100)))).toFixed(2);
    }
  };

  const calculateDailyTotalChangeInUSD = () => {
    return assets.reduce((total, asset) => total + parseFloat(calculateDailyChangeInUSD(asset)), 0).toFixed(2);
  };

  const handleToggle = () => {
    setShowDetails(!showDetails);
    handleSort('value');
  };

  useEffect(() => {
    const walletDiv = document.getElementById('Portfolio');
    if (walletDiv) {
      walletDiv.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, []);
  useEffect(() => {
    const BASE_URL_CRONOSCAN = "https://api.cronoscan.com/api";
    const BASE_URL_BITQUERY = "https://graphql.bitquery.io";
    const API_KEY_CRONOSCAN = "57VGVCF7UM4H74I2XA5AJ4WC4TAI2TJDKC";
    const API_KEY_BITQUERY = "BQYK1uykBjdFWVsfhDP6ocT2lAGJDGnu";

    const normalizeTimestamp = (timestamp) => {
        if (!timestamp) {
            console.error("Timestamp is missing or undefined.");
            return null;
        }

        const isSeconds = String(timestamp).length === 10;
        const normalized = isSeconds ? timestamp * 1000 : timestamp;

        if (isNaN(normalized) || normalized <= 0) {
            console.error("Invalid timestamp value:", timestamp);
            return null;
        }

        return normalized;
    };

    const fetchCronosTransactions = async (wallet, tokenAddress) => {
        const url = `${BASE_URL_CRONOSCAN}?module=account&action=tokentx&contractaddress=${tokenAddress}&address=${wallet}&page=1&offset=100&sort=asc&apikey=${API_KEY_CRONOSCAN}`;
        try {
            const response = await fetch(url);
            const data = await response.json();
            if (data.status === "1" && data.result) {
                return data.result;
            } else {
                console.warn("Error or empty transactions from Cronoscan:", data.message);
                return [];
            }
        } catch (error) {
            console.error("Error fetching Cronoscan transactions:", error);
            return [];
        }
    };

    const fetchTokenPriceAtTimestamp = async (tokenAddress, timestamp) => {
      const query = `{
        ethereum(network: cronos) {
          dexTrades(
            options: {asc: "block.timestamp.time", limit: 1},
            date: {
              since: "${new Date(timestamp - 7 * 24 * 60 * 60 * 1000).toISOString()}", 
              till: "${new Date(timestamp + 7 * 24 * 60 * 60 * 1000).toISOString()}"
            },
            baseCurrency: {is: "${tokenAddress}"}
          ) {
            tradeAmount(in: USD)
            baseAmount
            block {
              timestamp {
                time
              }
            }
          }
        }
      }`;
    
      try {
        const response = await fetch('https://graphql.bitquery.io', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-API-KEY': API_KEY_BITQUERY,
          },
          body: JSON.stringify({ query }),
        });
    
        const data = await response.json();
        const trades = data?.data?.ethereum?.dexTrades;
    
        if (trades && trades.length > 0) {
          // Calculate absolute time differences
          const sortedTrades = trades.map((trade) => ({
            ...trade,
            timeDifference: Math.abs(new Date(trade.block.timestamp.time).getTime() - timestamp),
          })).sort((a, b) => a.timeDifference - b.timeDifference);
    
          // Use the closest trade
          const closestTrade = sortedTrades[0];
          const tokenPrice = closestTrade.tradeAmount / closestTrade.baseAmount;
    
          console.log("Closest Trade:", closestTrade);
          console.log("Token Price:", tokenPrice);
    
          return tokenPrice;
        } else {
          console.warn("No price data available within ±7 days.");
          return 0;
        }
      } catch (error) {
        console.error("Error fetching price:", error);
        return 0;
      }
    };
    
    
 
 
    // Inside calculatePnL function
    const calculatePnL = async () => {
      setLoading(true);
      try {
        if (!wallets || wallets.length === 0 || !selectedTokenAddress) {
          console.warn("Invalid wallets or token address.");
          return;
        }
    
        // Normalize wallets to lowercase
        const normalizedWallets = wallets.map((wallet) => wallet.toLowerCase());
    
        let costBasis = new Decimal(0);
        let currentHoldings = new Decimal(0);
        let realizedPnL = new Decimal(0);
        let unrealizedPnL = new Decimal(0);
        let buysTotal = new Decimal(0);
        let sellsTotal = new Decimal(0);
    
        const transactions = (
          await Promise.all(
            wallets.map((wallet) =>
              fetchCronosTransactions(wallet, selectedTokenAddress)
            )
          )
        ).flat();
    
        transactions.sort((a, b) => parseInt(a.timeStamp) - parseInt(b.timeStamp));
    
        for (const tx of transactions) {
          const valueDecimal = tx.value && tx.tokenDecimal
            ? new Decimal(tx.value).div(new Decimal(10).pow(tx.tokenDecimal))
            : new Decimal(0);
    
          if (valueDecimal.isZero()) continue;
    
          const timestamp = tx.timeStamp * 1000;
          const tokenPrice = await fetchTokenPriceAtTimestamp(
            selectedTokenAddress,
            timestamp
          );
    
          if (!tokenPrice) continue;
    
          const usdValue = valueDecimal.mul(tokenPrice);
    
          // Filter out internal transfers
          const isInternalTransfer =
            normalizedWallets.includes(tx.to.toLowerCase()) &&
            normalizedWallets.includes(tx.from.toLowerCase());
    
          if (isInternalTransfer) {
            console.log("Skipping internal transfer:", tx);
            continue;
          }
    
          if (normalizedWallets.includes(tx.to.toLowerCase())) {
            console.log("Running Buy Transaction");
            // Buy transaction
            buysTotal = buysTotal.add(usdValue);
            costBasis = costBasis.add(usdValue);
            currentHoldings = currentHoldings.add(valueDecimal);
          } else if (normalizedWallets.includes(tx.from.toLowerCase())) {
            console.log("Running Sell Transaction");
            // Sell transaction
            if (currentHoldings.greaterThan(0)) {
              sellsTotal = sellsTotal.add(usdValue);
              const avgCost = costBasis.div(currentHoldings);
              const realizedGain = usdValue.sub(valueDecimal.mul(avgCost));
              realizedPnL = realizedPnL.add(realizedGain);
              costBasis = costBasis.sub(valueDecimal.mul(avgCost));
              currentHoldings = currentHoldings.sub(valueDecimal);
            }
          }
        }
    
        const matchedAsset = assets.find(
          (asset) =>
            asset.token_address.toLowerCase() === selectedTokenAddress.toLowerCase()
        );
    
        if (matchedAsset) {
          const currentPrice = new Decimal(matchedAsset.usd_price);
          unrealizedPnL = currentHoldings.mul(currentPrice).sub(costBasis);
          const totalPnL = realizedPnL.add(unrealizedPnL);
    
          setTotalBuys(buysTotal.toFixed(2));
          setTotalSells(sellsTotal.toFixed(2));
          setRealizedPnL(realizedPnL.toFixed(2));
          setUnrealizedPnL(unrealizedPnL.toFixed(2));
          setTotalPnL(totalPnL.toFixed(2));
        }
      } catch (error) {
        console.error("Error in PnL calculation:", error);
      } finally {
        setLoading(false);
      }
    };
    
    
    

if (wallets && selectedTokenAddress) {
  calculatePnL();
 
}

}, [wallets, selectedTokenAddress]);

const handleToggleWallets = () => {
  setShowWallets(!showWallets);
}


  return (
    <Box color={'white'} fontSize='1vmax' alignItems={'center'} justifyItems={'center'} textAlign={'center'}>
      <Text id='Portfolio' textAlign={'center'} fontSize={'2vmax'} mb="4">
        Cronos Portfolio tracker
      </Text>
      <Box className="portfolio-controls" mb="4">
        <Input
          type="text"
          value={address}
          onChange={handleInputChange}
          placeholder="Add Wallets"
          fontSize={'1vmax'}
          mr="1"
          width={'50%'}
          boxSize={'1,3'}
        />
        <Button fontSize={'1.2vmin'} colorScheme="orange" width='fit-content' onClick={handleButtonClick}>
          Save wallet and Fetch Assets
        </Button>
      </Box>
      <Box paddingBottom={'5vmin'} fontSize={'1vmax'} className="wallets" >
        <Text justifySelf={'center'} textAlign={'CENTER'}  width={'fit-content'}  color='white'  fontSize={'2VMAX'}>
          Tracked Wallets
        </Text>
        <Button   fontSize={'1vmax'} bgColor='black' color="orangered" variant="outline"  width={'full'}  onClick={handleToggleWallets}>
      {showWallets ? 'Hide wallets' : 'Show wallets'}
    </Button>
    <Collapse in={showWallets}>
        <ul style={{ rowGap: '1px', height: 'fit-content' }}>
          {wallets.map((wallet, index) => (
            <li style={{ padding: '1px', height: 'fit-content' }} key={index}>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Link
                  href={`https://cronoscan.com/address/${wallet}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  color="orangered"
                  _hover={{ textDecoration: 'underline', color: 'white' }}
                  textShadow="0 0 3px white"
                  mr={4}
                >
                  {wallet}
                </Link>
                <Button size="xs" colorScheme="red" onClick={() => handleRemoveWallet(wallet)}>
                  Remove
                </Button>
              </Box>
            </li>
          ))}
        </ul>
        </Collapse>
<Text  position={'sticky'} top='0' alignSelf={'center'} padding={'2vmax'} margin={'1vmax'} textShadow='0 0 30px orangered' width={'full'} height={'3.0vmax'} variant="outline" as={Text}  color= 'white' mt={6} fontSize={'1.2vmin'}    >
        Total value : <span  style={{ textUnderlineOffset:'1px' ,color:'green', padding:'2vmax', fontSize:'2.5vmax', }} >     ${calculateTotalValueUSD()} <span style={{ textUnderlineOffset:'1px' , padding:'2vmax', fontSize:'1.5vmax', }}  className={calculateDailyTotalChangeInUSD() < 0 ? 'negative' : 'positive'}>{calculateDailyTotalChangeInUSD() < 0 ? "" : '+'}{calculateDailyTotalChangeInUSD()}$</span> </span>
</Text>
      </Box>
  
      <Box
        content="contain"
        width="100%"
        overflow="hidden" // Add this line to handle overflow
        className="nft-cardswallet"
        
      >
          <Menu width='full' justifyItems='center'> <Text fontSize={'1.5vmax'}>SORT BY:</Text>
  <MenuButton justifySelf={'center'} paddingLeft={'2vw'} as={Button} rightIcon={<ChevronDownIcon />} bg="orangered" color="white" _hover={{ bg: 'white', color: 'orangered' }}>
    {getActiveSortLabel()}
  </MenuButton>
  <MenuList bg="#0a1b2f" color="white">
    {sortingOptions.map((option) => (
      <MenuItem
        key={option.value}
        onClick={() => handleSort(option.value)}
        bg={sortBy === option.value ? 'orangered' : 'transparent'}
        _hover={{ bg: 'white', color: 'orangered' }}
      >
        {option.label}
      </MenuItem>
    ))}
  </MenuList>
</Menu>
        <Table
  
        >
      <Box>
       </Box>
          <Thead  transform="scale(0.5)"  id="asset-table"></Thead>
          {assets.map((asset) =>
            asset.usd_value > 0 ? (
              <Tbody  maxWidth={'10px'} key={asset.token_address}>
   
                <Tr  transform="scale(0.85)"  id="assets" paddingTop="2vmax" rowGap="1px" fontSize="1.8vmax">
               
                <Td
  textAlign="center"
  maxWidth="10px"
  lineHeight="2.1vmax"
  rowGap="1px"
  paddingTop="1.8vmax"
  borderBottom="hidden"
  fontSize="2.0vmax"
  onClick={handleToggle}
  gap={"1"}
>
  <div    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    {asset.logo ? (
      <img 
        style={{ 
          borderRadius: '50%', 
          width: '2vmax', 
          marginRight: '0.5vmax' 
        }} 
        src={asset.logo} 
        alt={`${asset.symbol} logo`} 
      />
    ) : (
      <span 
        style={{ 
          display: 'inline-block', 
          width: '2vmax', 
          height: '2vmax', 
          marginRight: '0.5vmax', 
          borderRadius: '50%', 
          backgroundColor: '#4caf50', 
          color: '#fff', 
          textAlign: 'center', 
          lineHeight: '2vmax', 
          fontSize: '1.5vmax',
          fontWeight: 'bold' 
        }}
      >
        $
      </span>
    )}
    {asset.symbol.slice(0, 20)} {asset.symbol.length > 20 ? '...' : ''}
  </div>
</Td>
                  <Td
                    textAlign="center"
                    paddingTop="2vmax"
                    color="gold"
                    borderBottom="hidden"
                    fontSize="2vmax"
                   
                  >
                    ${parseFloat(asset.usd_value?.toFixed(2))} <Button
        size="fit-content"
        colorScheme="orangered"
        fontSize="1.2vmax"
        margin='0.5vmax'
        onClick={() => {
          setSelectedTokenAddress(asset.token_address);
          setIsModalOpen(true);
      }}
      >
        PNL
      </Button>
                  </Td>
               

                  <Td
                    rowGap="1vmax"
                    textAlign="center"
                    paddingTop="2vmax"
                    borderBottom="hidden"
                    fontSize="1.5vmax"
                    className={asset.usd_price_24hr_percent_change < 0 ? 'negative' : 'positive'}
                  >
                    {parseFloat(asset.usd_price_24hr_percent_change?.toFixed(2))}% ={' '}
                    {calculateDailyChangeInUSD(asset)}
                  </Td>
                </Tr>

                <Tr  transform="scale(0.85)"rowGap="0px">
                  <Td textAlign="center" borderBottom="2px outset grey" fontSize="1.5vmax">
                    <Link
                      lineHeight="2vmax"
                      textAlign="center"
                      textColor="orangered"
                      href={`https://dexscreener.com/cronos/${asset.token_address}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {asset.name.slice(0, 10)} {asset.name.length > 10 ? '...' : ''} 📈
                    </Link>
                  </Td>
                  <Td textAlign="center" borderBottom="2px outset grey" fontSize="1.5vmax">
                    {parseFloat(asset.balance_formatted).toFixed(2)}
                  </Td>
                  <Td textAlign="center" borderBottom="2px outset grey" fontSize="1.5vmax">
                    ${parseFloat(asset.usd_price?.toFixed(9))}
                  </Td>
                </Tr>
              </Tbody>
            ) : null
          )}
        </Table>
        {selectedTokenAddress && (
    <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalOverlay />
        <ModalContent maxWidth="fit-content" bg="blackAlpha.900">
            <ModalHeader textColor="white" fontSize="1.5vmax">TOKEN PNL</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                {loading ? (
                    <Box textAlign="center" p="4">
                        <Text color="white">Calculating PnL...</Text>
                        <Box mt="4" display="flex" justifyContent="center" alignItems="center">
                            <Spinner color="orange.500" size="lg" />
                        </Box>
                    </Box>
                ) : (
                    <Box
                        borderRadius="md"
                        borderWidth="1px"
                        borderColor={Number(totalPnL) >= 0 ? 'green.500' : 'red.500'}
                        color="white"
                        boxSize={'fit-content'}
                        display={'flex'}
                        padding="4" // Add padding for spacing
                        boxSizing="border-box" // Ensure border doesn't overlap content
                    >
                        <Table variant="simple" fontSize="1.5vmax" width="100%">
                        <Tbody>
    <Tr>
        <Th px="4" color="white" textAlign="left" fontSize="1.2vmax">
            <strong>Total Buys:</strong>
        </Th>
        <Td fontSize="1.2vmax" textAlign="right">
            ${Decimal.isDecimal(totalBuys) ? totalBuys.toFixed(2) : Number(totalBuys).toFixed(2)}
        </Td>
    </Tr>
    <Tr>
        <Th px="4" color="white" textAlign="left" fontSize="1.2vmax">
            <strong>Total Sells:</strong>
        </Th>
        <Td fontSize="1.2vmax" textAlign="right">
            ${Decimal.isDecimal(totalSells) ? totalSells.toFixed(2) : Number(totalSells).toFixed(2)}
        </Td>
    </Tr>
    <Tr>
        <Th px="4" color="white" textAlign="left" fontSize="1.2vmax">
            <strong>Realized PnL:</strong>
        </Th>
        <Td
            fontSize="1.2vmax"
            textAlign="right"
            color={Number(realizedPnL) >= 0 ? 'green.400' : 'red.400'}
        >
            ${Decimal.isDecimal(realizedPnL) ? realizedPnL.toFixed(2) : Number(realizedPnL).toFixed(2)}
        </Td>
    </Tr>
    <Tr>
        <Th px="4" color="white" textAlign="left" fontSize="1.2vmax">
            <strong>Unrealized PnL:</strong>
        </Th>
        <Td
            fontSize="1.2vmax"
            textAlign="right"
            color={Number(unrealizedPnL) >= 0 ? 'green.400' : 'red.400'}
        >
            ${Decimal.isDecimal(unrealizedPnL) ? unrealizedPnL.toFixed(2) : Number(unrealizedPnL).toFixed(2)}
        </Td>
    </Tr>
    <Tr>
        <Th px="4" color="white" textAlign="left" fontSize="1.2vmax">
            <strong>Total PnL:</strong>
        </Th>
        <Td
            fontSize="1.2vmax"
            textAlign="right"
            color={Number(totalPnL) >= 0 ? 'green.500' : 'red.500'}
        >
            ${Decimal.isDecimal(totalPnL) ? totalPnL.toFixed(2) : Number(totalPnL).toFixed(2)}
        </Td>
    </Tr>
</Tbody>

                        </Table>
                    </Box>
                )}
            </ModalBody>
        </ModalContent>
    </Modal>

)}

                
                   
                
      </Box>
    </Box>

  )}
export default App;