import { VStack, Heading, Button, Box,  Tag, Flex, Spacer, Radio, HStack, RadioGroup } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { NftGrid } from '../components/NftGrid';
import { useMoralis } from 'react-moralis';
import '../App.css';




export const Nfts = () => {

  const [refreshKey, setRefreshKey]=useState('0');
  const { account } = useMoralis();
  const [address, setAddress] = useState<string>(account || '');
  const [chain] = useState<string>('0x19');
  const [searchChain, setSearchChain] = useState<string>('0x19');
  const [searchAddress, setSearchAddress] = useState<string>(account || '');
  const [value, setValue] = React.useState('0xf96ec7C11D311920833753FAB9b174B6FD53517E')
  const [tokenaddresses, setTokenaddresses] = useState([String('0xf96ec7C11D311920833753FAB9b174B6FD53517E')]);
  
 
 
 
  useEffect(() => {
    if (!address && account) {
      setAddress(account);
  
    }
  }, [account, address, value]);

  useEffect(() => {
  setRefreshKey(refreshKey+'1');
      setTokenaddresses([value]);
      console.log(value);
    
  }, [value]);
  
  const [data, setData] = useState(null);

      
  const url1="https://api.cronoscan.com/api?module=account&action=tokenbalance&contractaddress="+value+"&address="
  const url2= String(address)
  const url3= "&tag=latest&apikey=57VGVCF7UM4H74I2XA5AJ4WC4TAI2TJDKC"
  
  

    useEffect(() => {
      const fetchData = async () => {
        const response = await fetch(url1+url2+url3);
        const json = await response.json();
        setData(json);
      
      };
    // eslint-disable-next-line
      fetchData();
    
    }, [value]);
    
    
  const [data2, setData2] = useState(null);

      
  const url12="https://api.cronoscan.com/api?module=account&action=tokenbalance&contractaddress="+value+"&address="
  const url22= '0x000000000000000000000000000000000000dead'
  const url32= "&tag=latest&apikey=57VGVCF7UM4H74I2XA5AJ4WC4TAI2TJDKC"
  
  

    useEffect(() => {
      const fetchData2 = async () => {
        const response2 = await fetch(url12+url22+url32);
        const json2 = await response2.json();
        setData2(json2);
      
      };
    // eslint-disable-next-line
      fetchData2();
    
    }, [value]);

  
  return (
    
    <VStack alignItems={'center'}>
       <RadioGroup onChange={setValue} value={value}>
      <HStack>
        <Radio fontSize={'1vmin'} value='0xD56AFcdB787e233325757D3ED7A987F11FB3fa08'>Reckless Robots</Radio>
        <Radio fontSize={'1vmin'} value='0xf96ec7C11D311920833753FAB9b174B6FD53517E'>Reckless Robots Legends</Radio>
        <Radio fontSize={'1vmin'} value='0x84Fda8563E6556a7Fb1578E10e114dB56d64638d'>Cronos Chibis</Radio>
      </HStack>
    </RadioGroup>
      <Box width="full">
      <Flex wrap="nowrap"  direction= 'row'   justify-content= 'space-between' >
      
      <Tag colorScheme='white' fontSize={'1vw'} boxSize={'fit-content'} margin= '2em' >{data ? <p>RECK BALANCE:        {(data)['result']} </p> : <p>Loading...</p>}</Tag>
  
      <Tag  colorScheme='white' fontSize={'1vw'} boxSize={'fit-content'} margin= '2em'>{data && data2 ?  <p>PERCENTAGE OWNED: - {(((data)['result']/(2100-data2['result']))*100).toFixed(2)} % </p> : <p>Loading...</p>}</Tag>
        <Spacer paddingBottom={5}></Spacer>
        </Flex>
      <form
          onSubmit={(event) => {
            event.preventDefault();
            setSearchAddress(address);
            setSearchChain(chain);
            setRefreshKey(refreshKey+'2');
            console.log(refreshKey);
          }}
        >
          <VStack>
            
           
             
           
          <Button padding='1' type="submit" fontSize={'1.3vw'} boxSize='full' minWidth={'-moz-fit-content'} colorScheme="blue">
              REFRESH COLLECTION
            </Button>
          </VStack>
        </form>
      </Box>

      {searchAddress && (
        <Box backgroundColor={'transparent'} pt={8}>
          <Heading size={'md'} mb={4}>
          <Tag  fontSize='1vw' colorScheme={'whiteAlpha'}>ROBOTS FOR {searchAddress}</Tag> 
       
          </Heading>
          <NftGrid  value={value} refreshKey={refreshKey} token_addresses= {tokenaddresses} address={searchAddress} chain={searchChain} />
          
        </Box>
      )}
    </VStack>
  );
};
