import {
  Button,
  HStack,
  Box,
  Flex,
  IconButton,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Stack,
} from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';

const Item = ({ children, to }: { children: string; to: string }) => {
  return (
    <Button
      padding="2"
      fontSize="1.5vw"
      color="#68738D"
      variant="ghost"
      as={Link}
      to={to}
      fontWeight="bold"
    >
      {children}
    </Button>
  );
};

export const Navigation = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box px={4}>
      {/* Navbar Header */}
      <Flex h={16} alignItems="center" justifyContent="space-between">
        <IconButton
          size="sm"
          icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
          aria-label="Toggle Menu"
          display={{ md: 'none' }}
          onClick={isOpen ? onClose : onOpen}
        />
        {/* Horizontal Links for Desktop */}
        <HStack as="nav" spacing={4} display={{ base: 'none', md: 'flex' }}>
          <Item to="/about">ABOUT</Item>
          <Item to="/nfts">RECKLESS NFTS HELD</Item>
          <Item to="Rampage/Demo">RAMPAGE DEMO</Item>
          <Item to="/wallet">PORTFOLIO TRACKER</Item>
          <Menu>
            <MenuButton as={Button} variant="ghost" fontSize="1.5vw" color="#68738D">
              MORE
            </MenuButton>
            <MenuList color="#68738D">
              <MenuItem as={Link} to="/vote">
                VOTE
              </MenuItem>
              <MenuItem as={Link} to="/fallen">
                FALLEN
              </MenuItem>
              <MenuItem as={Link} to="/ranking">
                RANKING
              </MenuItem>
            </MenuList>
          </Menu>
        </HStack>
      </Flex>

      {/* Vertical Links for Expanded View */}
      {isOpen && (
       <Box pb={4} display={{ md: 'none' }}>
       <Flex as="nav" wrap="wrap" justifyContent="center" gap={4}>
         <Button
           as={Link}
           to="/about"
           fontSize="2vw" /* Adjusted font size */
           fontWeight="bold"
           width="45%" /* Button box width */
           height="50px" /* Button box height */
           justifyContent="center"
           alignItems="center"
           textAlign="center"
           onClick={isOpen ? onClose : onOpen}  _hover={{ textDecoration: 'underline', color: 'black', backgroundColor:'orangere' }}
          
         >
           ABOUT
         </Button>
         <Button
           as={Link}
           to="/nfts"
           fontSize="2vw" /* Adjusted font size */
           fontWeight="bold"
           width="45%"
           height="50px"
           justifyContent="center"
           alignItems="center"
           textAlign="center"
           onClick={isOpen ? onClose : onOpen}  _hover={{ textDecoration: 'underline', color: 'black', backgroundColor:'orangered' }}
         >
           RECKLESS NFTS HELD
         </Button>
         <Button
           as={Link}
           to="Rampage/Demo"
           fontSize="2vw" /* Adjusted font size */
           fontWeight="bold"
           width="45%"
           height="50px"
           justifyContent="center"
           alignItems="center"
           textAlign="center"
           onClick={isOpen ? onClose : onOpen}  _hover={{ textDecoration: 'underline', color: 'black', backgroundColor:'orangered' }}
         >
           RAMPAGE DEMO
         </Button>
         <Button
           as={Link}
           to="/wallet"
           fontSize="2vw" /* Adjusted font size */
           fontWeight="bold"
           width="45%"
           height="50px"
           justifyContent="center"
           alignItems="center"
           textAlign="center"
           onClick={isOpen ? onClose : onOpen}  _hover={{ textDecoration: 'underline', color: 'black', backgroundColor:'orangered' }}
         >
           PORTFOLIO TRACKER
         </Button>
         <Button
           as={Link}
           to="/vote"
           fontSize="2vw" /* Adjusted font size */
           fontWeight="bold"
           width="45%"
           height="50px"
           justifyContent="center"
           alignItems="center"
           textAlign="center"
           onClick={isOpen ? onClose : onOpen}  _hover={{ textDecoration: 'underline', color: 'black', backgroundColor:'orangered' }}
         >
           VOTE
         </Button>
         <Button
           as={Link}
           to="/fallen"
           fontSize="2vw" /* Adjusted font size */
           fontWeight="bold"
           width="45%"
           height="50px"
           justifyContent="center"
           alignItems="center"
           textAlign="center"
           onClick={isOpen ? onClose : onOpen}  _hover={{ textDecoration: 'underline', color: 'black', backgroundColor:'orangered' }}
         >
           FALLEN
         </Button>
         <Button
           as={Link}
           to="/ranking"
           fontSize="2vw" /* Adjusted font size */
           fontWeight="bold"
           width="45%"
           height="50px"
           justifyContent="center"
           alignItems="center"
           textAlign="center"
           onClick={isOpen ? onClose : onOpen}  _hover={{ textDecoration: 'underline', color: 'black', backgroundColor:'orangered' }}
         >
           RANKING
         </Button>
       </Flex>
     </Box>
     
      )}
    </Box>
  );
};
