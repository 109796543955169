// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import axios from 'axios';

import {
  Box,
  Button,
  Flex,
  HStack,
  Radio,
  RadioGroup,
  Tag,
  Text,
  VStack,
} from '@chakra-ui/react';
import L70 from '../assets/L70.png'
import L50 from '../assets/L50.png'
import L30 from '../assets/L30.png'
import L20 from '../assets/L20.png'
import L10 from '../assets/L10.png'
import L5 from '../assets/L5.png'
import L3 from '../assets/L3.png'
import L1 from '../assets/L1.png'
import circuit from '../views/game/circuit.jpg'

interface Data {
  id: number;
  address: string;
  value: string;
  rank:number;

}

export const LRanking = () => {
  const [data, setData] = useState<Data[]>([]);
  const [filteredData, setFilteredData] = useState<Data[]>([]);
  const [value, setValue] = React.useState('0xf96ec7C11D311920833753FAB9b174B6FD53517E')

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(
        'https://cronos.org/explorer/api?module=token&action=getTokenHolders&contractaddress='+value+'&page=1&offset=500'
      );
      const sortedData = result.data.result.map((item: any) => ({
        id: item.id,
        address: item.address,
        value: item.value,
        rank:item.rank,
      }))
      .sort((a:Data, b:Data) => Number(b.value) - Number(a.value));
      const editedData = sortedData.map((item:Data, index:number) => ({
        ...item,
        rank: index + 1
      }));
      
      setData(editedData);
      setFilteredData(editedData);
    };

    fetchData();
  }, [value]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.trim().toLowerCase();
    const filtered = data.filter(
      (item) => item.address.toLowerCase().includes(value) || item.value.toLowerCase().includes(value)
    );
    setFilteredData(filtered);
  };

  const handleClear = () => {
    setFilteredData(data);
  };

  
  return (
    <Box fontSize="1.2vw" className="ranking" display="flex" flexDirection="column" alignItems="center">
    <Flex justifyContent="center" p={4}>
      <VStack>
      <RadioGroup onChange={setValue} value={value}>
      <HStack>
        <Radio fontSize={'1vmax'} value='0xD56AFcdB787e233325757D3ED7A987F11FB3fa08'>Reckless Robots</Radio>
        <Radio fontSize={'1vmax'} value='0xf96ec7C11D311920833753FAB9b174B6FD53517E'>Reckless Robots Legends</Radio>
        <Radio fontSize={'1vmax'} value='0x84Fda8563E6556a7Fb1578E10e114dB56d64638d'>Cronos Chibis</Radio>
      </HStack>
    </RadioGroup>

        <HStack>
          <Text  padding='0.5vmax' fontSize="1.2vw" fontWeight="bold">Search:</Text>
            <input  placeholder='wallet address' style={{color:'black', padding: '0.5vmax'}} type="text" onChange={handleSearch} />
       
          <Button fontSize="1.0vw" padding="0.4vw" boxSize="fit-content" id="clear" onClick={handleClear}>
            Clear
          </Button>
        </HStack>
      </VStack>
    </Flex>

    <Box>
      {filteredData.map((item) => (
        <Box backgroundImage={circuit} backgroundSize={'100%'} width="auto" key={item.id} p={2} borderWidth={'2vmin'} className='nft-cards'  display="flex" justifyContent="center">
          <HStack  display="flex" justifyContent="space-between">
            <VStack  color={'black'} fontSize="1vmax" display="flex" justifyContent="space-between">
              <Text fontWeight="bold">{item.id}</Text>
              <Text>RANK: {item.rank}</Text>
              <br />
              <Text>      {item.address.slice(0, 6) + "*".repeat(item.address.length - 5 - 5) + item.address.slice(-4)}</Text>
        
              <br />
              <Tag size="1vmax">ROBOTS HELD: {item.value}</Tag>
            </VStack>

            {Number(item.value) >= 70 && <img src={L70} alt="Image above 100" width="150vmin" height="auto"  />}
            {Number(item.value) > 49 && Number(item.value) <= 69 && (
              <img src={L50} alt="Image above 70" width="150vmin" height="auto" />
            )}
            {Number(item.value) > 29 && Number(item.value) <= 49 && (
              <img src={L30} alt="Image above 50" width="150vmin" height="auto" />
            )}
            {Number(item.value) > 19 && Number(item.value) <= 29 && (
              <img src={L20} alt="Image above 30" width="150vmin" height="auto" />
            )}
            {Number(item.value) > 9 && Number(item.value) <= 19 && (
              <img src={L10} alt="Image above 15" width="150vmin" height="auto" />
            )}
            {Number(item.value) > 4 && Number(item.value) <= 9 && (
              <img src={L5} alt="Image above 5" width="150vmin" height="auto" />
            )}
            {Number(item.value) > 2 && Number(item.value) <= 4 && (
              <img src={L3} alt="Image above 3" width="150vmin" height="auto" />
            )}
            {Number(item.value) > 0 && Number(item.value) <= 2 && (
              <img src={L1} alt="Image above 1" width="150vmin" height="auto" />
            )}
          </HStack>
        </Box>
      ))}
    </Box>
  </Box>
  );
};
