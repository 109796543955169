import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, Flex, Grid, GridItem, Text, Progress, VStack, Tag, HStack } from '@chakra-ui/react';
import pic1 from '../RR_Logo1.png';
import pic2 from '../../assets/eb.png';
import pic3 from '../../assets/CDC.png';
import pic4 from '../../assets/RRLOGO.png';
import BG2 from '../game/Clicker/Floor2.png';

// Define icons and images
const icons = [
  { type: 'icon', value: 'K' },
  { type: 'icon', value: 'J' },
  { type: 'icon', value: 'Q' },
  { type: 'image', src: pic1 },
  { type: 'image', src: pic2 },
  { type: 'icon', value: '7️⃣' }, // Jackpot symbol
  { type: 'image', src: pic3 },
  { type: 'icon', value: '7️⃣' }, // Jackpot symbol,
  { type: 'icon', value: '7️⃣' }, // Jackpot symbol
  { type: 'icon', value: '7️⃣' }, // Jackpot symbol
  { type: 'icon', value: '7️⃣' }, // Jackpot symbol
  { type: 'icon', value: '7️⃣' }
];
const scatterSymbol = { type: 'image', src: pic4 }; // Define the scatter symbol

// Constants
const MIN_BET = 10;
const MAX_BET = 500;
const STARS_TO_ADVANCE = 50; // Stars required to level up
const JACKPOT_PERCENTAGES = {
  small: 0.05, // 5% of the bet
  medium: 0.1, // 10% of the bet
  large: 0.15 // 15% of the bet
};
const PAYOUT_MULTIPLIERS = {

  [pic1]: 5,
  [pic2]: 5,
  [pic3]: 5,
};

const SlotGame: React.FC = () => {
  const [reels, setReels] = useState([
    [getRandomIcon(), getRandomIcon(), getRandomIcon()],
    [getRandomIcon(), getRandomIcon(), getRandomIcon()],
    [getRandomIcon(), getRandomIcon(), getRandomIcon()],
    [getRandomIcon(), getRandomIcon(), getRandomIcon()],
    [getRandomIcon(), getRandomIcon(), getRandomIcon()]
  ]);
  const [stars, setStars] = useState(0);
  const [level, setLevel] = useState(1);
  const [freeSpins, setFreeSpins] = useState(0);
  const [isSpinning, setIsSpinning] = useState(false);
  const [winnings, setWinnings] = useState(0);
  const [balance, setBalance] = useState(1000);
  const [bet, setBet] = useState(MIN_BET);
  const [winningSymbols, setWinningSymbols] = useState<string[]>([]);
  const [jackpotSmall, setJackpotSmall] = useState(20);
  const [jackpotMedium, setJackpotMedium] = useState(500);
  const [jackpotLarge, setJackpotLarge] = useState(1000);
  const [battleOutcome, setBattleOutcome] = useState<React.ReactNode>(null);

  const spinningRef = useRef(false);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    let spinTimeout: NodeJS.Timeout;

    if (isSpinning) {
      spinningRef.current = true;
      interval = setInterval(() => {
        setReels(reels => reels.map(reel => [getRandomIcon(), ...reel.slice(0, -1)]));
      }, 100);

      spinTimeout = setTimeout(() => {
        spinningRef.current = false;
        clearInterval(interval);

        const finalReels = reels.map(() => [
          getRandomIcon(),
          getRandomIcon(),
          getRandomIcon()
        ]);

        setReels(finalReels);
        const { totalWinnings, starsCollected, scatterCount, jackpotWon } = calculateWinnings(finalReels);
        setWinnings(totalWinnings);
        setStars(prev => prev + starsCollected);
        setBalance(prev => prev + totalWinnings);

        if (jackpotWon) {
          if (jackpotWon === 'small') {
            setBalance(prev => prev + jackpotSmall);
            setJackpotSmall(20);
          } else if (jackpotWon === 'medium') {
            setBalance(prev => prev + jackpotMedium);
            setJackpotMedium(500);
          } else if (jackpotWon === 'large') {
            setBalance(prev => prev + jackpotLarge);
            setJackpotLarge(1000);
          }
        }

        if (scatterCount >= 3) {
          setFreeSpins(prev => prev + scatterCount * 2);
        }

        if (stars + starsCollected >= STARS_TO_ADVANCE) {
          setStars((stars + starsCollected) % STARS_TO_ADVANCE);
          setLevel(prev => Math.min(prev + 1, 5));
          if (level === 2) {
            setFreeSpins(prev => prev + 10);
          }
        }

        setIsSpinning(false);
      }, 2000);
    }

    return () => {
      clearInterval(interval);
      clearTimeout(spinTimeout);
    };
  }, [isSpinning]);

  const spinReels = () => {
    if (!isSpinning) {
      if (freeSpins > 0) {
        setFreeSpins(prev => prev - 1);
      } else if (balance >= bet) {
        setBalance(prev => prev - bet);
        setJackpotSmall(prev => prev + bet * JACKPOT_PERCENTAGES.small);
        setJackpotMedium(prev => prev + bet * JACKPOT_PERCENTAGES.medium);
        setJackpotLarge(prev => prev + bet * JACKPOT_PERCENTAGES.large);
      } else {
        return;
      }

      setWinnings(0);
      setWinningSymbols([]);
      setBattleOutcome(null);

      setIsSpinning(true);
    }
  };

  const calculateWinnings = (finalReels: any[][]) => {
    let totalWinnings = 0;
    let starsCollected = 0;
    let scatterCount = 0;
    let jackpotWon: string | null = null;

    const iconOccurrences: { [key: string]: number[] } = {};

    for (let reelIndex = 0; reelIndex < finalReels.length; reelIndex++) {
      finalReels[reelIndex].forEach((icon) => {
        const iconType = icon.value || icon.src;
        if (!iconOccurrences[iconType]) {
          iconOccurrences[iconType] = Array(finalReels.length).fill(0);
        }
        iconOccurrences[iconType][reelIndex]++;
      });
    }

    for (const [iconType, occurrences] of Object.entries(iconOccurrences)) {
      if (occurrences[0] >= 1 && occurrences[1] >= 1 && occurrences[2] >= 1) {
        const product = occurrences[0]+occurrences[1]+occurrences[2]+occurrences[3]+occurrences[4];
        const multiplier = PAYOUT_MULTIPLIERS[iconType] || 1;
     

        const updatedSymbols = [...winningSymbols, iconType];
        setWinningSymbols(updatedSymbols);

        if (iconType === '7️⃣' && occurrences[0] >= 1 && occurrences[1] >= 1 && occurrences[2] >= 1) {
        if (iconType === '7️⃣' && occurrences.every(count => count >= 1)) {
          if (occurrences[4] >= 1) jackpotWon = 'large';}
        
          if (iconType === '7️⃣' && occurrences[0] >= 1 && occurrences[1] >= 1 && occurrences[2] >= 1 && occurrences[3] >= 1  && occurrences[4] === 0) {
            jackpotWon = 'medium';}

          if (iconType === '7️⃣' && occurrences[0] >= 1 && occurrences[1] >= 1 && occurrences[2] >= 1 &&occurrences[3] === 0  ) {
        jackpotWon = 'small';}

          
          setBattleOutcome(
            <VStack>
             
            <Tag className='nft-cards'
            fontSize={'3vw'}
            color={'green'}
            animation= 'result-animation 4s ease-in-out'
          background= 'black'
          position= 'absolute'
          top={'50vmax'}
         
          zIndex= '300'
        
            opacity='0' 
          
              textAlign={'center'}
              justifyContent={'center'}
              flexDirection={'column'}
              padding={'1vw'}
             
              >
                  {jackpotWon} Jackot won,<br></br>
                   Congrats
                   <br></br> legend!
                </Tag>
        
                  
                     </VStack>
          );
        
          totalWinnings += (bet * multiplier * product) + (jackpotWon === 'small' ? jackpotSmall: jackpotWon === 'medium' ? jackpotMedium: jackpotWon === 'large' ? jackpotLarge: 0);
        }
      
      }
    }

    return { totalWinnings, starsCollected, scatterCount, jackpotWon, battleOutcome };
  };



  const renderIcon = (icon: any) => {
    const isWinning = winningSymbols.includes(icon.value || icon.src);

    return icon.type === 'image' ? (
      <img
        src={icon.src}
        alt="slot-icon"
        className={isWinning ? 'fire' : 'none'}
        style={{
          width: '70px',
          height: '70px',
          objectFit: 'contain',
          background: 'transparent'
        }}
      />
    ) : (
      <Box
        as="span"
        fontSize="xxx-large"
        color="white"
     
      >
        {icon.value}
      </Box>
    );
  };



 


  return (
    <VStack>
      <Box textAlign="center" mb={4}>
        <Text fontSize="lg" color="blue.500">Small Jackpot: {jackpotSmall.toFixed(2)}</Text>
        <Text fontSize="lg" color="orange.500">Medium Jackpot: {jackpotMedium.toFixed(2)}</Text>
        <Text fontSize="lg" color="red.500">Large Jackpot: {jackpotLarge.toFixed(2)}</Text>
      </Box>
      <Box borderWidth="10px" width="60%" className="slots2" textAlign="center" mb={4}>
        <Text fontSize="lg" color="white">Level: {level}</Text>
        <Progress value={(stars / STARS_TO_ADVANCE) * 100} size="md" colorScheme="yellow" />
        <Text fontSize="sm" color="yellow.500">{`${stars} / ${STARS_TO_ADVANCE} ⭐`}</Text>
        <Text fontSize="lg" color="yellow.500">{`Free Spins: ${freeSpins}`}</Text>
      </Box>
      <Flex
        width="full"
        backgroundImage={BG2}
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        className="slots2"
        direction="column"
        alignItems="center"
        justifyContent="center"
        height="70vh"
        color="white"
      >
        <Grid className="slots2" templateColumns="repeat(5, 1fr)" gap={4}>
          {reels.map((reel, reelIndex) => (
            <GridItem
              key={reelIndex}
              w="100px"
              p="2"
              className="slots2"
              borderWidth="5px"
            >
              <Grid templateRows="repeat(3, 1fr)" gap={2}>
                {reel.map((icon, iconIndex) => (
                  <GridItem
                    key={iconIndex}
                    textAlign="center"
                    boxSize="70px"
                    className={winningSymbols.includes(String(icon.value)) ? 'fire' : 'none'}
                  >
                    {renderIcon(icon)}
                  </GridItem>
                ))}
              </Grid>
            </GridItem>
          ))}
        </Grid>
        <HStack  width=' 100%' direction={'row'} className='nft-cards' mt={6} alignItems="center" justifyContent="center" >
          <HStack>
          <Button colorScheme="teal" onClick={() => setBet(prev => Math.max(prev - 10, MIN_BET))} disabled={bet <= MIN_BET}>
            -
          </Button>
          <Text fontSize="xl" color="yellow.500">{`Bet: ${bet}`}</Text>
          <Button colorScheme="teal" onClick={() => setBet(prev => Math.min(prev + 10, MAX_BET))} disabled={bet >= MAX_BET}>
            +
          </Button>
          <Button colorScheme="green" onClick={spinReels} disabled={isSpinning || (freeSpins <= 0 && balance < bet)}>
            {isSpinning ? 'Spinning...' : freeSpins > 0 ? 'Free Spin' : 'Spin'}
          </Button>
          </HStack>
        
        <Box  paddingLeft={'20px'} textAlign="center" mt={4}>
          <Text fontSize="xl" color="green.500">Winnings: {winnings}</Text>
          <Text fontSize="lg" color="white">Balance: {balance}</Text>
        </Box>
        </HStack>
        {battleOutcome}
      </Flex>
    </VStack>
  );
};

const getRandomIcon = () => icons[Math.floor(Math.random() * icons.length)];

export default SlotGame;
