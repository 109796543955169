import React from 'react';
import { Container } from '@chakra-ui/react';
import {Text} from '@chakra-ui/react'


const Footer = () => {
  return (
    <Container textAlign={'center'} maxW="container.lg" py={4}>
      <Text fontSize={'0.7vw'}>
       BROUGHT TO YOU BY RECKLESS ROBOTS 2024, THE RECKLESS OVERSEER
      </Text>
    </Container>
  );
};

export default Footer;
