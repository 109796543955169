import React, { useEffect, useState } from 'react';
import { AbsoluteCenter, Box, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Spacer, VStack } from '@chakra-ui/react';
import Map from './map.png';
import RR from './frame1.png';
import Player from './beast.jpg'
import scroll from './scroll.png'
import Skilltree from './skilltree';
import initiateMeltdown from './skilltree'
import { Link } from 'react-router-dom';

import chooseLegend from './chooseLegend.jpg'
import MarketPlace from './MarketPlace.png'
import ResearchCenter from './ResearchCenter.png'

type MapCircleProps = {
  OneManArmyRCRO: number;
  isOpen: boolean;
  onClose: () => void;
 
};

const MapModal: React.FC<MapCircleProps> = ({ isOpen, onClose, OneManArmyRCRO }) => {
const [PlayerImage, setPlayerImage]=useState('')
useEffect(() => {
 

  
const GetplayerImage = ()=> {
  
  const NFTimage1=localStorage.getItem('NFTimage');
const NFTimage=NFTimage1?.replace('ipfs://', 'https://ipfs.io/ipfs/') ?NFTimage1?.replace('ipfs://', 'https://ipfs.io/ipfs/') :chooseLegend;

    if (NFTimage){
    setPlayerImage(NFTimage);}
    else {
      setPlayerImage(Player);}
    

  }

  GetplayerImage();
}, [])
  const interestPoints = [
    // Your interest points
    { x: 900, y: 350 }, { x: 700, y: 650 }, { x: 150, y: 750 },
    { x: 370, y: 500 }, { x: 100, y: 320 }, { x: 520, y: 280 },
    { x: 830, y: 240 }, { x: 730, y: 30 }, { x: 400, y: 160 },
    { x: 170, y: 60 }, { x: 280, y: -100 }
  ];
  const FinalPoint = [
    // Your interest points
    { x: 280, y: -100 }
  ];

  const calculateIntermediatePoints = () => {
    let allPoints = [];

    for (let i = 0; i < interestPoints.length - 1; i++) {
      allPoints.push(interestPoints[i]);

      const steps = 4; // Number of steps between interest points
      const deltaX = (interestPoints[i + 1].x - interestPoints[i].x) / (steps + 1);
      const deltaY = (interestPoints[i + 1].y - interestPoints[i].y) / (steps + 1);

      for (let step = 1; step <= steps; step++) {
        allPoints.push({
          x: interestPoints[i].x + deltaX * step,
          y: interestPoints[i].y + deltaY * step
        });
      }
    }

    allPoints.push(interestPoints[interestPoints.length - 1]);
    return allPoints;
  };

  const allPoints = calculateIntermediatePoints();
  let lastReachedPoint = { x: 0, y: 0 };
  if (OneManArmyRCRO > 0  && OneManArmyRCRO <= allPoints.length) {
    lastReachedPoint = allPoints[OneManArmyRCRO - 1];
  } 

  const renderMapCircles = () => {
    return allPoints.map((point, index) => {
      const isInterestPoint = interestPoints.some(p => p.x === point.x && p.y === point.y);
      const circleRadius = isInterestPoint ? '30' : '15';
      const circleStrokeWidth = isInterestPoint ? '10' : '5'; 
      const circleColor = index < OneManArmyRCRO ? 'blue' : 'red';
  
      let text;

if (OneManArmyRCRO % 5 === 0 && OneManArmyRCRO !== 0) {
  // For exact multiples of 5 (5, 10, 15, 20, 25, ...)
  switch (OneManArmyRCRO) {
    case 5: text = 'Edge of the Abyss'; break;
    case 10: text = 'Pits of the forgotten'; break;
    case 15: text = 'Grove of the forsaken'; break;
    case 20: text = 'Keep of the fallen'; break;
    case 25: text = 'Traders shore'; break;
    case 30: text = "Dark guardian's keep"; break;
    case 35: text = "Glacier's Grasp"; break;
    case 40: text = "Wildwood's whisper"; break;
    case 45: text = 'Apex of the Arcane'; break;
    // Add more cases as needed
    default: text = "Bastion of Darkness "; break; // Default for multiples of 5 not explicitly listed
  }
} else {
  // For all numbers not multiples of 5 (1-4, 6-9, 11-14, ...)
  switch (Math.floor(OneManArmyRCRO / 5)) {
    case 0: text = 'path of the Titans'; break; // 1-4
    case 1: text = 'Tides of Doom'; break; // 6-9
    case 2: text = 'Dunes of dystopia'; break; // 11-14
    case 3: text = 'Embers of ascention'; break; // 16-19
    case 4: text = 'Route of traders'; break; // 21-24
    case 5: text = 'Stream of Guardians'; break; // 26-29
    case 6: text = 'Frozen Valley'; break; // 31-34
    case 7: text = 'River of Ancients'; break; // 36-39
    case 8: text = 'Path of the forsaken '; break; // 41-44
    // Add more cases as needed
    default: text = 'Peaks of Peril'; break; // Default for others not explicitly listed
  }
}

// Use `text` for your text field or image source


      if (isInterestPoint || index <= OneManArmyRCRO) {
        return (
          <>
            <circle
              key={`circle-${index}`}
              cx={point.x}
              cy={point.y}
              r={circleRadius}
              fill="none"
              stroke={circleColor}
              strokeWidth={circleStrokeWidth}
              filter="url(#shadow)"
            />
  
            {isInterestPoint && (
          <>
            <image 
                href={scroll} 
                x={500} 
                y={-650} 
                width={500} 
                height={700} 
            />
        
       
            <text 
                x={500 + 500 / 2} // Center of the image horizontally
                y={-650 + 700 / 2} // Center of the image vertically
                fill="black" // Text color
                font-size="1.5vmax" // Font size
                text-anchor="middle" // Align text horizontally
                dominant-baseline="middle" // Align text vertically
            >
              {text}
            </text>
            </>
            )}
      
        </>);
      }
    });
  };
  return (
    <VStack>
    <Modal size='lg' isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent style={{ paddingTop:'10vh'}} backgroundColor={'black'} >
   
     <ModalHeader></ModalHeader>
      
        {OneManArmyRCRO >= 50 &&  (
  <>
 
    <VStack id='chosenTeams' zIndex={100} position={'absolute'} top={'550px'}>  
    <Button fontSize={'1vw'} bgColor='black' color="orangered" variant="outline">
     <Link to='../Rampage/'>QUIT GAME TO INITIATE MELTDOWN</Link>
   </Button>
   

</VStack></>
              )}
        <ModalBody >
                
 
          <div  className='nft-cards' style={{  justifySelf:'center', scale:'1.4', width: '100%', height: '600px' }}>

          <ModalCloseButton size={'fit-content'} zIndex={100} position={'absolute'} padding={'0.2vmax'}  fontSize={'0.6vmax'} bgColor='rgba(0, 0, 0, 0.6)' color="orangered" variant="outline" top={'490px'}>CLOSE</ModalCloseButton>  

            <img src={Map} alt="Map Background" style={{ width: '100%', height: '600px' }} />
            <svg style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1 }} viewBox="0 0 1000 500">
              <defs >
                <filter  id="shadow" width={'530%'} height="530%">
                  <feDropShadow dx="5" dy="5" stdDeviation="2"/>
                </filter>
                <clipPath id="circleClip">
                  <circle  cx={lastReachedPoint.x} cy={lastReachedPoint.y} r="50" />
                </clipPath>
                <clipPath id="circleClips">
                  <circle cx={lastReachedPoint.x} cy={lastReachedPoint.y} r="30" />
                </clipPath>
                <clipPath id="circleClip1">
                  <circle cx={280} cy={-100} r="50" />
                </clipPath>
                <clipPath id="circleClips1">
                  <circle cx={280} cy={-100} r="30" />
                </clipPath>
              </defs>
              {renderMapCircles()}
              
              </svg>
              <svg  style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 3 }} viewBox="0 0 1000 500">
              {OneManArmyRCRO > 0 && OneManArmyRCRO<50 ?   (
                    <image className='mapEmblem'
                  href={RR}
                  x={lastReachedPoint.x - 50}
                  y={lastReachedPoint.y - 50}
                  height="100"
                  width="100"
                  clipPath="url(#circleClip)"
                  filter='url(#shadow)'
                />
              ):      <image className='mapEmblem'
              href={RR}
              x={280-50}
              y={-100-50}
              height="100"
              width="100"
              clipPath="url(#circleClip1)"
              filter='url(#shadow)'
            />}
              {OneManArmyRCRO > 0 && OneManArmyRCRO<50 ?   (
                    <image className='mapEmblem'
                  href={PlayerImage !== null ? PlayerImage: Player}
                  x={lastReachedPoint.x - 50}
                  y={lastReachedPoint.y - 50}
                  height="100"
                  width="100"
                  clipPath="url(#circleClips)"
                />
                ):      <image className='mapEmblem'
                href={PlayerImage !== null ? PlayerImage: Player}
                x={280-50}
              y={-100-50}
                height="100"
                width="100"
                clipPath="url(#circleClips1)"
              />
              }

{OneManArmyRCRO >= 50 &&  (
                    <image className='mapEmblem'
                  href={RR}
                  x={lastReachedPoint.x + 100}
                  y={lastReachedPoint.y + 50}
                  height="100"
                  width="100"
                  clipPath="url(#circleClip)"
                  filter='url(#shadow)'
                />
              )}

                {OneManArmyRCRO >= 50 && (
                    <image className='mapEmblem'
                    href={PlayerImage !== null ? PlayerImage: Player}
                  x={lastReachedPoint.x + 100}
                  y={lastReachedPoint.y + 50}
                  height="100"
                  width="100"
                  clipPath="url(#circleClips)"
                />
              )}

<image className={OneManArmyRCRO >= 10 ? 'mapEmblem' : 'mapEmblem_disabled'}
                  href={MarketPlace}
                  x={150}
                  y={750}
                  height="100"
                  width="100"
                 
              
                />
               <image className={OneManArmyRCRO >= 30 ? 'mapEmblem' : 'mapEmblem_disabled'}
                  href={ResearchCenter}
                  x={400}
                  y={160}
                  height="100"
                  width="100"
                
                 
                />


         </svg>
         

          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
    </VStack>
  );
};


export default MapModal;
