/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Flex, Spacer, Box, VStack, Img, HStack, Wrap, WrapItem, Alert, AlertDescription, AlertIcon, AlertTitle, Spinner, List, Text, Divider } from '@chakra-ui/react';
import { useMoralis, useNFTBalances } from 'react-moralis';
import RampageFront from './game/RampageFront.jpg'
import { Link } from 'react-router-dom';

import eb from './game/eb.jpg'

import Commander from './game/commander.jpg'
import Beast from './game/beast.jpg'
import Void from './game/void.jpg'
import LegendsBeginning from '../assets/RampageStart.gif';
import Moralis from 'moralis';




const About = () => {

 
 
  useEffect(() => {
    setTimeout(() => {
      
   
    const PlayDiv2 = document.getElementById('RampageStart');
    if (PlayDiv2) {
      PlayDiv2.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, 500);
  },[] ); 

  return (
    <Box  p={2}>
      <VStack >
      <Text color={'white'} fontSize="3vmin" fontWeight="bold">RECKLESS ROBOTS LEGENDS MINT IS LIVE</Text>
      <HStack>
      <Text color={'grey'} fontSize="2vmin">WHERE: <span>     <Link  to='https://app.ebisusbay.com/drops/reckless-robots-legends'> Mint your Legends NFTs at <span style={{textUnderlineOffset:'5px' ,textDecoration:'underline'}}> EBISUSBAY.com </span></Link></span></Text><img style={{ width:'4vmin'}}src={eb}></img>
      </HStack>
      <Text color={'grey'}  fontSize="2vmin">PRICE: 200CRO(WL) / 300CRO (REGULAR)</Text>
      <Text color={'grey'}  fontSize="2vmin">TOTAL SUPPLY: 3000NFTS</Text>

      <Link color={'grey'}   id='RampageStart' style={{textAlign:'center',padding:'3vmin', color:'orangered', margin:'0.5vmax'}} to='https://app.ebisusbay.com/drops/reckless-robots-legends'> <img  className='modal' style={{boxShadow:'0 0 100px orangered', width:'40vmax'}}src={LegendsBeginning}></img> </Link>
  

      <Text fontSize="3vmin" fontWeight="bold" mt={4}>DISTINCTIVE FEATURES:</Text>
      <Box  alignItems={'center'} textAlign={'center'} >
        <VStack padding={5} spacing={5}>
        <Text color={'grey'}  fontSize="2vmin">AWESOME HAND-DRAWN ROBOT ARTS</Text>
        <Text color={'grey'} fontSize="2vmin">ACCELERATED PASSIVE INCOME THROUGH BURNS</Text>
        <Text color={'grey'}  fontSize="2vmin">LEGENDARY BATTLES</Text>
        <Text color={'grey'}  fontSize="2vmin">HOLDING BASED/WEIGHTED COMMUNITY VOTING</Text>
        </VStack>
      </Box>

      <Text fontSize="3vmin" fontWeight="bold" mt={4}>ROYALTY REVENUES:</Text>
      <Box  alignItems={'center'} textAlign={'center'} >
        <VStack padding={5} spacing={5}>
        <Text color={'grey'} fontSize="2vmin">9% TO HOLDER REWARDS</Text>
        <Text color={'grey'} fontSize="2vmin">1% TO PROJECT WALLET / CONSTANT EXPENSES</Text>
        </VStack>
      </Box>

      <Divider my={10} borderColor="white" borderWidth={'0.3vmin'} />

      <Box alignItems={'center'} textAlign={'center'}>
  <VStack padding={5} spacing={5}>
    <Text color={'grey'} fontSize="2vmin">
      Reckless Robots Legends is a collection of 3000 hand-drawn NFTs deployed on the Cronos Chain.
      The collection consists of three (3) main Classes starring the Commander, The Void and The Beast.
    </Text>
    <Wrap>
    <img style={{ borderRadius:'50%', width:'11vmin'}}src={Commander}></img>
    <img style={{ borderRadius:'50%',width:'11vmin'}}src={Void}></img>
    <img style={{ borderRadius:'50%',width:'11vmin'}}src={Beast}></img>
    </Wrap>
    <Text color={'grey'} fontSize="2vmin">
      Legends NFTs work as tokens in the already published NFT game the Rampage. Many high tier Cronos projects have already chosen to take part in the Rampage, such as Ebisu's Bay, Crooks Finance and Crodex to name a few.
    </Text>
    <Text color={'grey'} fontSize="2vmin">
      Winning team members will share the prize pool as per their respective amount of in game tokens earned! Read more about the game here: <Link style={{textDecoration:'underline'}} color={'orangered'} to='https://medium.com/@recklessrobots'>https://medium.com/@recklessrobots</Link>
    </Text>
    <Text color={'grey'} fontSize="2vmin">
      The game is hosted at the Reckless Command Center DApp that also provides the holders opportunity to manage and transfer their Legends. Holding based Community voting has also been already implemented, as the holders are currently choosing the use of the first batch of holder rewards.
    </Text>
    <Text color={'grey'} fontSize="2vmin">
      The development is done by the founder of Reckless Robots, which provides longevity and sustainability for the project.
    </Text>
  </VStack>
</Box>
      </VStack>
    </Box>

  );
};
   



   

export default About;


