/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Flex, Spacer, Text, Box, VStack, Img, HStack, Wrap, WrapItem, Alert, AlertDescription, AlertIcon, AlertTitle, Spinner } from '@chakra-ui/react';
import { useMoralis, useNFTBalances } from 'react-moralis';
import RampageFront from './game/RampageFront.jpg'
import { Link } from 'react-router-dom';
import Timer from './tournamentTimer';
import eb from './game/eb.jpg'
import RampageStart from './game/RAMPAGE.jpg';
import Moralis from 'moralis';
import RR from '../components/RR_nft_ranks.json'






const Home = () => {

  const { account } = useMoralis();
  const [address, setAddress] = useState<string>(account || '');
 
 

  useEffect(() => {
    setTimeout(() => {
      
   
    const PlayDiv2 = document.getElementById('RampageStart');
    if (PlayDiv2) {
      PlayDiv2.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, 500);
  },[] ); 

  return (
    <VStack  borderWidth='4vmin' paddingBottom={''} className='nft-cards' alignContent={'center'}>
       
          <Link  id='RampageStart' style={{ paddingTop:'10vh', textAlign:'center', color:'orangered', margin:'0.5vmax'}} to='./Rampage/'> <img   className='modal' style={{ boxShadow:'0 0 100px orangered', width:'40vmax'}}src={RampageStart}></img>CLICK TO ENTER THE BATTLEFIELD</Link>
          <Spacer paddingBottom={'2vmax'}></Spacer>
          <Timer />
        
          <Spacer paddingBottom={'2vmax'}></Spacer>
          <Wrap  borderWidth='10px' fontSize={'1vmax'}  justifyContent='space-between' width='80%' className='modal' flexDirection={'row'}>
     
          <WrapItem  justifyContent='space-evenly'>
      
             <img  width={'7%'} height={'7%'} src={eb}></img> 
         
            <Box textAlign={'center'}>
        
             <Link  to='https://app.ebisusbay.com/drops/reckless-robots-legends'> Mint your Legends NFTs (rampage tokens) <br></br> at EBISU'S BAY</Link>
             </Box>
             <img width={'7%'} height={'7%'} src={eb}></img> </WrapItem>
          </Wrap>
      <Flex flexDirection='column' alignContent={'center'} justifyContent='center'>
    
     



<Spacer paddingBottom={10}/>
<Box justifyItems={'center'} textAlign='center'>

</Box>
<Spacer  paddingBottom={5}/>

<Text textAlign={'center'} fontSize={'1vw'} >PLEASE NOTE THAT ONLY THE CHAMBER OF FALLEN CAN BE <br></br>USED WITHOUT SIGNING IN WITH YOUR WALLET.</Text>
<Spacer  paddingBottom={5}/>
</Flex></VStack>
      );
    };
  
   /*
   <Text textAlign={'center'} fontSize={'1vw'}> IN HERE YOU CAN: </Text><br></br><Text textAlign={'center'} fontSize={'1vw'}>
- CONTROL AND INSPECT YOUR GEN1 ROBOT AND LEGENDS FLEET AND SEE HOW THEY COMPARE TO THE TOTAL SUPPLY<br></br>
- HONOR THE FALLEN ONES AND INSPECT THE STATISTICS OF GEN 1<br></br>
- TAKE PART IN THE DECISION MAKING THROUGH VOTES 
- PLAY THE RECKLESS RAMPAGE GAME </Text> */
                
           



   

export default Home;


